import { IEigentuemerwechselResponseBody, IEigentuemerwechselBody } from "../../store/slices/eigentuemerwechsel";
import { adminInstance, instance } from "../api";

const create = (data: IEigentuemerwechselBody) => {
  return instance.post("/eigentuemerwechsel", data).then((res) => res.data);
};

const getAdminEigentuemerwechselData = ({
  signal,
  direction,
  endDate,
  page,
  pageSize,
  payerType,
  searchValue,
  sort,
  startDate,
  status,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  signal: AbortSignal;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: string[];
  payerType?: string[];
}) => {
  return adminInstance
    .get<{ data: IEigentuemerwechselResponseBody[]; totalCount: number }>("/admin/eigentuemerwechsel", {
      params: {
        direction,
        endDate,
        page,
        pageSize,
        payerType,
        searchValue,
        sort,
        startDate,
        status,
      },
      signal,
    })
    .then((res) => res.data);
};

const getAdminEigentuemerwechselItemById = (id: string) => {
  return adminInstance
    .get<IEigentuemerwechselResponseBody>(`/admin/eigentuemerwechsel/${id}`)
    .then((res) => res.data);
};

const geEigentuemerwechselDoc = (id: string) => {
  return adminInstance
    .get(`/admin/eigentuemerwechsel/${id}/pdf`, {
      responseType: "blob",
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Eingetuemerwechsel.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const Eigentuemerwechsel = {
  get: {
    getAdminEigentuemerwechselData,
    getAdminEigentuemerwechselItemById,
    geEigentuemerwechselDoc,
  },
  post: {
    create,
  },
};

export default Eigentuemerwechsel;
