import { adminInstance, instance } from "../api";

export interface ISperrmuel {
  id: string;
  userName: string;
  userSurname: string;
  street: string;
  postcode: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  email: string;
  pickUpTrash: {
    trashType: string;
    date: string;
    tourNumber: number;
    comment?: string;
    isBigAmount?: boolean;
    pickupPlace?: string;
  }[];
  pickupPlace: string;
  message: string;
  isSynchronizedWithTicketSystem: boolean;
  createdAt: string;
  homeNumber: number;
  hausNrZusatz?: string;
  trashTypeData?: {
    name: string;
    value: string;
    trashType?: number;
    abfallNr?: number;
    tournr?: number;
  }[];
  trashVolume?: number;
  pickupHomeNumber?: number;
  pickupStreet?: string;
  pickupHausNrZusatz?: string;
  requestType?: string;
  isAccepted?: boolean;
  isExpressService?: boolean;
  status?: "check" | "accepted" | "declined" | "inWork" | "error";
  checkReason?: string;
  homeNumberTo?: number;
  pickupHomeNumberTo?: number;
  errorReason?: string;
  trashType?: string;
  history?: {
    id: string;
    createdAt: string;
    status: string;
    email: string;
    firstName: string;
    lastName: string;
  }[];
  //for calw
  serviceType?: string;
  buchungszeichen?: string;
}

const clearBulkyGarbageRequest = (data: any) => {
  return instance
    .post("/sperrmuel/sperrmuell-anmelden", data)
    .then((res) => {
      return { statusCode: res.status, message: res.data.message };
    })
    .catch((err) => {
      return { statusCode: err.response.status, message: err.data.message };
    });
};

const acceptBulkyGarbageRequest = (id: string) => {
  return instance
    .get<{ message: string }>(`/sperrmuel/token/${id}`)
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const getAvailableDates = (obj: any) => {
  return instance
    .get<string[]>(
      `/sperrmuel/get-dates?leistungNr=${obj.leistungNr}&gemeindeNr=${obj.gemeindeNr}&strasseNummer=${obj.strasseNummer}&hausNr=${obj.hausNr}`
    )
    .then((res) => res.data);
};

const getSperrmuellScheduleStatus = () => {
  return instance
    .get("/sperrmuel/sperrmuel-schedule-status")
    .then((res) => res.data);
};

const getTownAreaStreets = () => {
  // TODO: old version `/sperrmuel/townarea-streets?ortsteilNummer=${ortsteilNummer}`
  return instance
    .get<
      {
        strasseBezeichnung: string;
        strasseNummer: number;
        blockedHomeNumbers: { _id: string; from: string; to: string }[];
        ignore?: "active" | "partially" | "ignore";
        whiteListHomeNumbers?: { from: string; to: string }[];
        whiteListIgnore?: "active" | "partially" | "ignore";
        greyListHomeNumbers?: { from: string; to: string }[];
        greyListIgnore: "active" | "partially" | "ignore";
        gemeindeNummer: number;
      }[]
    >(`/sperrmuel/townarea-streets`)
    .then((res) => res.data);
};

const getTownAreas = () => {
  return instance
    .get<{ ortsteilNummer: number; orteilBezeichnung: string }[]>(
      "/sperrmuel/town-areas"
    )
    .then((res) => res.data);
};

const getGemeinden = () => {
  return instance
    .get<
      {
        id: string;
        gemeindeBezeichnung: string;
        gemeindePostleitzahl: number;
        gemeindeNummer: number;
      }[]
    >("/sperrmuel/gemeinden")
    .then((res) => res.data);
};

const getStuecklisten = ({
  gemeindeNummer,
  leistungNr,
}: {
  gemeindeNummer: number;
  leistungNr: number;
}) => {
  return instance
    .get<
      {
        abfallnr: number;
        abfallbez: string;
        sortnr: number;
        faktor: number;
      }[]
    >("/sperrmuel/stueckliste", {
      params: {
        gemeindeNummer,
        leistungNr,
      },
    })
    .then((res) => res.data);
};

const getSperrmuelData = ({
  direction,
  pageSize,
  page,
  searchValue,
  sort,
  status,
  signal,
  endDate,
  startDate,
  serviceType,
  trashType,
}: {
  sort?: string;
  direction?: string;
  searchValue?: string;
  page?: number;
  pageSize?: number;
  status?: string[];
  serviceType?: string[];
  trashType?: string[];
  signal?: AbortSignal;
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  return adminInstance
    .get<{
      data: ISperrmuel[];
      totalCount: number;
    }>(`/admin/sperrmuellanmeldungen`, {
      params: {
        sort,
        direction,
        searchValue,
        page,
        pageSize,
        status,
        startDate,
        endDate,
        serviceType,
        trashType,
      },
      signal,
    })
    .then((res) => res.data);
};

const getAvailableExpressDates = () => {
  return instance
    .get<string[]>(`sperrmuel/get-express-dates`)
    .then((res) => res.data);
};

const getSperrmuelById = (id: string) => {
  return adminInstance
    .get<ISperrmuel>(`admin/sperrmuellanmeldungen/${id}`)
    .then((res) => res.data);
};

const getEbwoDates = ({
  pickupHomeNumber,
  pickupStreet,
  isOnGreyOrWhiteList,
  gemeindeNr,
  leistungNr,
  strasseNummer,
  isExpress,
}: {
  pickupStreet: string;
  pickupHomeNumber: number;
  isOnGreyOrWhiteList?: boolean;
  strasseNummer?: string;
  leistungNr?: string[];
  gemeindeNr?: string;
  isExpress?: boolean;
}) => {
  return instance
    .get<{
      dates: string[];
      data: { [key: string]: { termin: string; tournr: number } };
    }>(`sperrmuel/ebwo-dates`, {
      params: {
        pickupHomeNumber,
        pickupStreet,
        isOnGreyOrWhiteList,
        gemeindeNr,
        leistungNr,
        strasseNummer,
        isExpress,
      },
    })
    .then((res) => res.data);
};

const confirmSperrmuell = ({
  id,
  accepted,
  status,
}: {
  id: string;
  accepted?: boolean;
  status?: string;
}) => {
  return adminInstance
    .put<ISperrmuel>(`admin/sperrmuellanmeldungen/${id}/accept`, {
      accept: accepted,
      status,
    })
    .then((res) => res.data);
};

const deleteSperrmuellById = ({ id }: { id: string }) => {
  return adminInstance
    .delete(`admin/sperrmuellanmeldungen/${id}`)
    .then((res) => res.data);
};

const clearEbwoBulkyGarbageRequest = (data: any) => {
  return instance
    .post("/sperrmuel/ebwo/sperrmuell-anmelden", data)
    .then((res) => {
      return { statusCode: res.status, message: res.data.message };
    })
    .catch((err) => {
      return { statusCode: err.response.status, message: err.data.message };
    });
};

const getLraDates = ({
  pickupHomeNumber,
  pickupStreet,
  isOnGreyOrWhiteList,
  gemeindeNr,
  leistungNr,
  strasseNummer,
  isExpress,
  postcode,
}: {
  pickupStreet: string;
  pickupHomeNumber: number;
  isOnGreyOrWhiteList?: boolean;
  strasseNummer?: string;
  leistungNr?: string[];
  gemeindeNr?: string;
  isExpress?: boolean;
  postcode: string;
}) => {
  return instance
    .get<{
      dates: string[];
      data: { [key: string]: { termin: string; tournr: number } };
    }>(`sperrmuel/lra-dates`, {
      params: {
        pickupHomeNumber,
        pickupStreet,
        isOnGreyOrWhiteList,
        gemeindeNr,
        leistungNr,
        strasseNummer,
        isExpress,
        postcode,
      },
    })
    .then((res) => res.data);
};
const getCalwDates = ({
  leistungNr,
  streetNummer,
  ortsteilNummer,
  hausNr,
}: {
  leistungNr?: string;
  streetNummer?: string;
  ortsteilNummer?: string;
  hausNr?: string;
}) => {
  return instance
    .get<{
      dates: string[];
      data: { [key: string]: { termin: string; tournr: number } };
    }>(`sperrmuel/calw-dates`, {
      params: {
        leistungNr,
        streetNummer,
        ortsteilNummer,
        hausNr,
      },
    })
    .then((res) => res.data);
};

const changeSperrmuellItemsStatuses = ({
  ids,
  status,
}: {
  ids: string[];
  status: string;
}) => {
  return adminInstance
    .put<ISperrmuel[]>(`/admin/sperrmuell/status`, {
      status,
      ids,
    })
    .then((res) => res.data);
};

const getCalwSperrmuellDoc = () => {
  return instance
    .get("/sperrmuell/calw/docs", {
      responseType: "blob",
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Sperrmuell_und_Altholzabfuhr.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const downloadExcel = ({
  searchValue,
  status,
  endDate,
  startDate,
  serviceType,
  trashType,
}: {
  searchValue?: string;
  status?: string[];
  serviceType?: string[];
  startDate?: Date | null;
  endDate?: Date | null;
  trashType?: string[];
}) => {
  return adminInstance
    .get(`/admin/excel/sperrmuell`, {
      responseType: "blob",
      params: {
        searchValue,
        status,
        endDate,
        startDate,
        serviceType,
        trashType,
      },
    })
    .then((res) => {
      const href = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Sperrmuellanmeldungen.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const Spermuell = {
  get: {
    acceptBulkyGarbageRequest,
    getAvailableDates,
    getSperrmuellScheduleStatus,
    getTownAreaStreets,
    getTownAreas,
    getSperrmuelData,
    getAvailableExpressDates,
    getSperrmuelById,
    getEbwoDates,
    getLraDates,
    getGemeinden,
    getStuecklisten,
    getCalwSperrmuellDoc,
    getCalwDates,
    downloadExcel,
  },
  post: {
    clearBulkyGarbageRequest,
    clearEbwoBulkyGarbageRequest,
  },
  put: {
    confirmSperrmuell,
    changeSperrmuellItemsStatuses,
  },
  delete: {
    deleteSperrmuellById,
  },
};

export default Spermuell;
