import React from "react";
import style from "./style.module.scss";
import { Button } from "../../../../../components";
import appConfig from "../../../../../util/appConfig";
import {
  confirmSperrmuell,
  deleteSperrmuellById,
} from "../../../../../store/slices/sperrmuell";
import { statuses } from "../../../../AdminSperrmuelanmeldungen";
import { multiply, add } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { useNavigate, useParams } from "react-router-dom";

const ebwoTrashVolumeInfo = [
  {
    name: "Kleiner Schrank, Kommode, Truhe, Küchenschrank, Regal, Garderobe",
    volume: 0.15,
  },
  {
    name: "Wohnzimmer-, Schlafzimmerschrank (Schranktüren mit Glas-> Rest)",
    volume: 0.6,
  },
  {
    name: "Bettgestell, Lattenrost (Holzrahmen)",
    volume: 0.2,
  },
  {
    name: "Stuhl, Bank, Tisch (Holz)",
    volume: 0.1,
  },
  {
    name: "Küchenarbeitsplatte (max. 2 Meter lang)",
    volume: 0.1,
  },
  {
    name: "Couch, Schlafcouch",
    volume: 1.5,
  },
  {
    name: "Eckcouch",
    volume: 2.5,
  },
  {
    name: "Sessel",
    volume: 0.4,
  },
  {
    name: "Matratze",
    volume: 0.3,
  },
  {
    name: `Teppich, PVC, Linoleum (Angabe in m²) - max. 2 Meter breit, gerollt`,
    volume: 0.02,
  },
  {
    name: "Badezimmerschrank, Spiegel",
    volume: 0.02,
  },
  {
    name: "Stuhl, Bank, Tisch (Kunststoff)",
    volume: 0.1,
  },
  {
    name: "Regentonne (Max. 200 Liter)",
    volume: 0.1,
  },
  {
    name: "Wäschekorb, Koffer",
    volume: 0.08,
  },
  {
    name: "Aquarium, Terrarium, Glasvitrine, Schranktür mit Spiegel- oder Glasfront",
    volume: 0.1,
  },
  {
    name: "Kindersitz, Spiegel- und Freizeitartikel: Bobbycar, Zelt, Rutsche, o.ä.",
    volume: 0.1,
  },
  {
    name: "Kühl-/Gefrierschrank, Gefriertruhe",
    volume: 0.4,
  },
  {
    name: "Waschmaschine, Trockner, Spülmaschine, Elektroherd, Gasherd",
    volume: 0.3,
  },
  {
    name: "Dunstabzugshaube",
    volume: 0.1,
  },
  {
    name: "Fernseher, Monitore, HiFi-Anlage, Computer, Kopierer",
    volume: 0.2,
  },
  {
    name: "Elektro-Rasenmäher",
    volume: 0.05,
  },
  {
    name: "Kleingeräte (Staubsauger etc.), Steh-/Deckenleuchten (ohne Leuchtmittel)",
    volume: 0.01,
  },
  {
    name: "Lattenrost (Metallrahmen)",
    volume: 0.2,
  },
  {
    name: "Metallstangen (max. 2 Meter lang), Heizkörper",
    volume: 0.1,
  },
  {
    name: "Tisch, Stuhl, Bank",
    volume: 0.1,
  },
  {
    name: "Fahrrad, Kettcar, Dreirad, Kinderwagen, Sportgerät",
    volume: 0.1,
  },
  {
    name: "Rasenmäher (ohne Betriebsmittel)",
    volume: 0.05,
  },
  {
    name: "Bügelbrett, Sonnenschirm (Metall)",
    volume: 0.01,
  },
  {
    name: "Schranktür mit Spiegel- oder Glaseinsatz",
    volume: 0.2,
  },
];

interface IEbwo {}

const Ebwo = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { role } = useAppSelector((state) => state.admin);
  const sperrmuelItemData = useAppSelector(
    (state) => state.sperrmuell.admin.sperrmuelItemInfo
  );

  return (
    <>
      <div className={style.title}>Sperrmüll Information</div>
      <div className={style.tableInfoWrapper}>
        {sperrmuelItemData?.status ? (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Sperrmüllstatus</div>
            <div className={style.rowInfo}>
              {statuses[sperrmuelItemData?.status]}
            </div>
          </div>
        ) : null}
        {sperrmuelItemData?.status === "check" &&
        sperrmuelItemData?.checkReason ? (
          <div className={style.rowWrapper}>
            <div className={style.rowTitle}>Grund für Prüfing</div>
            <div className={style.rowInfo}>{sperrmuelItemData.checkReason}</div>
          </div>
        ) : null}
        <div className={style.rowWrapper}>
          <div className={style.rowTitle}>Sperrmülltyp</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.trashTypeData?.filter(
              (el) => el.trashType === 8 || el.trashType === 6
            ).length ? (
              <div>
                <div
                  style={{
                    color: "#353b3f",
                    fontFamily: "Raleway",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  Holz
                </div>
                <div>
                  {sperrmuelItemData?.trashTypeData
                    ?.filter((el) => el.trashType === 8 || el.trashType === 6)
                    .map((i, idx) => {
                      return (
                        <div className={style.trashTypeWrapper} key={idx}>
                          <div className={style.trashTypeTitle}>
                            Name: {i.name}; <br />
                            Quantität: {i.value}; <br />
                            Gesamtvolumen:{" "}
                            {multiply(
                              ebwoTrashVolumeInfo.find(
                                (el) => el.name === i.name
                              )?.volume!,
                              +i.value
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  Summe:{" "}
                  {sperrmuelItemData?.trashTypeData
                    ?.filter((el) => el.trashType === 8 || el.trashType === 6)
                    .reduce((prev, curr) => {
                      prev = add(
                        prev,
                        multiply(
                          ebwoTrashVolumeInfo.find(
                            (el) => el.name === curr.name
                          )?.volume!,
                          +curr.value
                        )
                      );
                      return prev;
                    }, 0)}
                </div>
              </div>
            ) : null}
            {sperrmuelItemData?.trashTypeData?.filter(
              (el) => el.trashType === 10 || el.trashType === 7
            ).length ? (
              <div>
                <div
                  style={{
                    color: "#353b3f",
                    fontFamily: "Raleway",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  Rest
                </div>
                <div>
                  {sperrmuelItemData?.trashTypeData
                    ?.filter((el) => el.trashType === 10 || el.trashType === 7)
                    .map((i, idx) => {
                      return (
                        <div className={style.trashTypeWrapper} key={idx}>
                          <div className={style.trashTypeTitle}>
                            Name: {i.name}; <br />
                            Quantität: {i.value}; <br />
                            Gesamtvolumen:{" "}
                            {multiply(
                              ebwoTrashVolumeInfo.find(
                                (el) => el.name === i.name
                              )?.volume!,
                              +i.value
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  Summe:{" "}
                  {sperrmuelItemData?.trashTypeData
                    ?.filter((el) => el.trashType === 10 || el.trashType === 7)
                    .reduce((prev, curr) => {
                      prev = add(
                        prev,
                        multiply(
                          ebwoTrashVolumeInfo.find(
                            (el) => el.name === curr.name
                          )?.volume!,
                          +curr.value
                        )
                      );
                      return prev;
                    }, 0)}
                </div>
              </div>
            ) : null}
            {sperrmuelItemData?.trashTypeData?.filter(
              (el) => el.trashType === 40 || el.trashType === 5
            ).length ? (
              <div>
                <div
                  style={{
                    color: "#353b3f",
                    fontFamily: "Raleway",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  Elektrogeräte/Metall
                </div>
                <div>
                  {sperrmuelItemData?.trashTypeData
                    ?.filter((el) => el.trashType === 40 || el.trashType === 5)
                    .map((i, idx) => {
                      return (
                        <div className={style.trashTypeWrapper}>
                          <div key={idx} className={style.trashTypeTitle}>
                            Name: {i.name}; <br />
                            Quantität: {i.value}; <br />
                            Gesamtvolumen:{" "}
                            {multiply(
                              ebwoTrashVolumeInfo.find(
                                (el) => el.name === i.name
                              )?.volume!,
                              +i.value
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  Summe:{" "}
                  {sperrmuelItemData?.trashTypeData
                    ?.filter((el) => el.trashType === 40 || el.trashType === 5)
                    .reduce((prev, curr) => {
                      prev = add(
                        prev,
                        multiply(
                          ebwoTrashVolumeInfo.find(
                            (el) => el.name === curr.name
                          )?.volume!,
                          +curr.value
                        )
                      );
                      return prev;
                    }, 0)}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={style.lastRowWrapper}>
          <div className={style.rowTitle}>Gesamt Sperrmüllvolumen</div>
          <div className={style.rowInfo}>
            {sperrmuelItemData?.trashVolume?.toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: role === "SUPERADMIN" ? "space-between" : "flex-end",
        }}
      >
        {role === "SUPERADMIN" ? (
          <Button
            text="Löschen"
            width="200"
            backgroundColor="white"
            isOutlined
            outlineColor={"red"}
            textColor="red"
            onClick={() => {
              if (itemId) {
                dispatch(
                  deleteSperrmuellById({
                    id: itemId,
                    navigate: () => {
                      navigate("/admin/sperrmuell-anmeldungen");
                    },
                  })
                );
              }
            }}
          />
        ) : null}
        {sperrmuelItemData?.status === "check" && sperrmuelItemData ? (
          <div
            style={{
              // textAlign: "end",
              display: "flex",
            }}
          >
            <Button
              text="Akzeptieren"
              width="200"
              backgroundColor={appConfig.mainColor}
              marginRight="20"
              onClick={() => {
                dispatch(
                  confirmSperrmuell({
                    id: sperrmuelItemData.id,
                    accepted: true,
                  })
                );
              }}
            />
            <Button
              text="Stornieren"
              width="200"
              backgroundColor="white"
              isOutlined
              outlineColor={"red"}
              textColor="red"
              onClick={() => {
                dispatch(
                  confirmSperrmuell({
                    id: sperrmuelItemData.id,
                    accepted: false,
                  })
                );
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Ebwo;
