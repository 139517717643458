import React, { useEffect, useState } from "react";
import { SortDescriptor } from "@progress/kendo-data-query";
import {
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
  TableLastItem,
} from "../../components";
import { useAppDispatch, useAppSelector } from "../../store";
import { format } from "date-fns";
import { statuses } from "../AdminSperrmuelanmeldungen";
import appConfig from "../../util/appConfig";
import { GridColumn } from "@progress/kendo-react-grid";
import {
  clearAdminBehaelter,
  getAdminBehaelterverwaltung,
} from "../../store/slices/behaelterverwaltung";

export const requestTypes: { [key: string]: string } = {
  defectBin: "Defekte Tonne",
  removeBin: "Tonne abgemeldet",
  disappearedBin: "Tonne verschwunden",
  updateBin: "Änderung der Tonnengröße",
  freeBioBin: "Verhältnis Bio / Rest",
  newBin: "Neue Tonne angemeldet",
};

const AdminBehaelterverwaltung = () => {
  const dispatch = useAppDispatch();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { itemsCount, behaeltervervaltunData } = useAppSelector(
    (state) => state.behaelterverwaltung.admin
  );

  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [status, setStatus] = useState<string[]>([]);
  const [requestType, setRequestType] = useState<string[]>([]);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end || end === null) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };
  const changeRequestType = (st: string[]) => {
    setRequestType([...st]);
  };

  const generateSelectOptions = (type: string) => {
    switch (type) {
      case "lra": {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.check, value: "check" },
          { label: statuses.declined, value: "declined" },
          { label: statuses.error, value: "error" },
        ];
      }
      default: {
        return [
          { label: statuses.accepted, value: "accepted" },
          { label: statuses.inWork, value: "inWork" },
        ];
      }
    }
  };

  const generateRequestTypes = (type: string) => {
    switch (type) {
      case "lra": {
        return [
          { label: requestTypes.defectBin, value: "defectBin" },
          { label: requestTypes.removeBin, value: "removeBin" },
          { label: requestTypes.disappearedBin, value: "disappearedBin" },
          { label: requestTypes.updateBin, value: "updateBin" },
          { label: requestTypes.freeBioBin, value: "freeBioBin" },
        ];
      }
      default: {
        return [];
      }
    }
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminBehaelterverwaltung({
        direction: sort[0]?.dir,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        signal,
        startDate,
        endDate,
        status,
        requestType,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged, status, requestType]);

  useEffect(() => {
    return () => {
      dispatch(clearAdminBehaelter());
    };
  }, []);

  const selectorOptions = [
    {
      placeholder: "Bitte Status wählen",
      isActive: ["lra"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: generateSelectOptions(appConfig.logo).filter(
        (el) => !status.includes(el.value)
      ),
      value: status.map((el) => {
        return {
          value: el,
          label: statuses[el],
        };
      }),
      onChange: changeStatuses,
    },
    {
      placeholder: "Bitte Abfallart wählen",
      isActive: ["lra"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: generateRequestTypes(appConfig.logo),
      value: requestType.map((el) => {
        return { value: el, label: el };
      }),
      onChange: changeRequestType,
    },
  ];

  return (
    <div>
      <AdminHeader pageTitle={"BEHÄLTERVERWALTUNG"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={behaeltervervaltunData.map((el) => {
            return {
              ...el,
              name: `${el.usersurname}, ${el.username} `,
              createdAt: el.createdAt
                ? format(new Date(el.createdAt), "dd.MM.yyyy HH:mm")
                : "",
              // date: el.date ? format(new Date(el.date), "dd.MM.yyyy") : "",
              status: el.status ? statuses[el.status] : "",
              requestType: el.requestType ? requestTypes[el.requestType] : "",
              isFreeBioBin: el?.isFreeBioBin ? "Ja" : "Nein",
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          items={[
            { field: "status", title: "Status" },
            { field: "createdAt", title: "Datum (Meldung)" },
            { field: "name", title: "Nachname, Vorname" },
            { field: "requestType", title: "Auftragsart" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={itemsCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          lastItem={<GridColumn cell={TableLastItem} width="50px" />}
          selectorOptions={selectorOptions}
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminBehaelterverwaltung;
