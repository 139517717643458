import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { SortDescriptor } from "@progress/kendo-data-query";
import { useAppDispatch, useAppSelector } from "../../store";
import { getAdminBehaeltergemeinschaft } from "../../store/slices/behaeltergemeinschaft";
import {
  AdminCheckbox,
  AdminContentWrapper,
  AdminHeader,
  Loader,
  TableGrid,
  TableLastItem,
} from "../../components";
import { format } from "date-fns";
import { getAdminEigenkompostierer } from "../../store/slices/eigenkompostierer";
import {
  GridCellProps,
  GridColumn,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { useNavigate } from "react-router-dom";
import appConfig from "../../util/appConfig";
import { de } from "date-fns/locale";

export const statuses: { [key: string]: string } = {
  accepted: "Fertig",
  declined: "Storniert",
  inWork: "Offen",
};

const AdminEigenkompostierer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { eigenkompostiererData, totalCount } = useAppSelector(
    (state) => state.eigenkompostierer.admin
  );

  const [sort, setSort] = useState<Array<SortDescriptor>>([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datesChanged, setDatesChanged] = useState({});
  const [status, setStatus] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allItemsSelected, setAllItemsSelected] = useState(false);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end || end === null) {
      setDatesChanged({ updated: true });
    }
  };

  const changeHandler = (val: string) => {
    setValue(val);
  };

  const pageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeHandler = (size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const changeStatuses = (st: string[]) => {
    setStatus([...st]);
  };

  const setAcceptedStatus = () => {
    // dispatch(
    //   changeItemsStatuses({
    //     ids: eigenkompostiererData.map((el) => el.id),
    //     status: "accepted",
    //   })
    // );
  };

  const allItemsSelectHandler = () => {
    if (!allItemsSelected) {
      setAllItemsSelected(true);
      const ids = eigenkompostiererData.map((i) => i.id);
      setSelectedItems([...ids]);
      return;
    }
    if (allItemsSelected) {
      setAllItemsSelected(false);
      setSelectedItems([]);
      return;
    }
  };

  const selectItemHandler = (id: string) => {
    if (selectedItems.includes(id)) {
      const t = selectedItems.filter((i) => i !== id);
      return setSelectedItems([...t]);
    }

    if (!selectedItems.includes(id)) {
      return setSelectedItems((prev) => [...prev, id]);
    }
  };

  const FirstItem = (props: GridCellProps) => {
    return (
      <td
        // className="k-command-cell"
        style={
          {
            // backgroundColor: 'white',
          }
        }
      >
        <AdminCheckbox
          checked={selectedItems.includes(props.dataItem.id)}
          changeHandler={() => {
            selectItemHandler(props.dataItem.id);
          }}
        />
      </td>
    );
  };
  const FirstHeaderItem = (props: GridHeaderCellProps) => {
    return (
      <div
        style={{
          margin: "0",
          padding: "0",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          // border: "none",
          // backgroundColor: "rgba(0, 0, 0, 0.02) !important",
        }}
        // className={style.qwe}
      >
        <AdminCheckbox
          checked={allItemsSelected}
          changeHandler={allItemsSelectHandler}
        />
      </div>
    );
  };

  useEffect(() => {
    const abortController = new window.AbortController();
    const signal = abortController.signal;

    dispatch(
      getAdminEigenkompostierer({
        direction: sort[0]?.dir,
        page,
        pageSize,
        searchValue: value,
        sort: sort[0]?.field,
        signal,
        startDate,
        endDate,
        status,
      })
    );

    return () => {
      if (signal && abortController.abort) {
        abortController.abort();
      }
    };
  }, [sort, page, pageSize, value, datesChanged, status]);

  const selectorOptions = [
    {
      placeholder: "Bitte Status wählen",
      isActive: ["calw"].includes(appConfig.logo),
      defaultValue: undefined,
      selectOptions: [
        { label: statuses.accepted, value: "accepted" },
        { label: statuses.inWork, value: "inWork" },
        { label: statuses.declined, value: "declined" },
      ].filter((el) => !status.includes(el.value)),
      value: status.map((el) => {
        return {
          value: el,
          label: statuses[el],
        };
      }),
      onChange: changeStatuses,
    },
  ];

  return (
    <div>
      <AdminHeader pageTitle={"Eigenkompostierer"} hasBtnGroup={false} />
      <AdminContentWrapper>
        <TableGrid
          data={eigenkompostiererData?.map((el) => {
            return {
              ...el,
              createdAt: el.createdAt
                ? format(new Date(el.createdAt), "dd.MM.yyyy, HH:mm")
                : "",
              homeNum: `${el.homeNumber} ${
                el.hausNrZusatz ? ", " + el.hausNrZusatz : ""
              }`,
              name: `${el.userName}, ${el.userSurname}`,
              street: `${el.street} ${el.homeNumber}${
                el.hausNrZusatz ? " " + el.hausNrZusatz : ""
              }`,
              status: statuses[el.status],
            };
          })}
          sort={sort}
          sortable={true}
          onSortChange={setSort}
          items={[
            { field: "status", title: "Status" },
            { field: "createdAt", title: "Datum (Meldung)" },
            { field: "bookingCode", title: "Kassenzeichen" },
            { field: "name", title: "Nachname, Vorname" },
            { field: "street", title: "Straße" },
            { field: "postCode", title: "PLZ" },
            { field: "placePart", title: "Ort" },
          ]}
          itemsForPdf={[
            { field: "status", title: "Status" },
            { field: "createdAt", title: "Datum (Meldung)" },
            { field: "bookingCode", title: "Kassenzeichen" },
            { field: "name", title: "Nachname, Vorname" },
            { field: "placePart", title: "Ort" },
            { field: "postCode", title: "PLZ" },
            { field: "street", title: "Straße" },
            { field: "homeNumber", title: "Hausnummer" },
            { field: "hausNrZusatz", title: "Hausnummerzusatz" },
            { field: "message", title: "Nachricht" },
          ]}
          searchInputValue={value}
          onSearchInputValueChange={changeHandler}
          withPager={true}
          page={page}
          pageSize={pageSize}
          pageChangeHandler={pageChangeHandler}
          pageSizeHandler={pageSizeHandler}
          itemsCount={totalCount}
          onDateChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          withDatePicker
          lastItem={<GridColumn cell={TableLastItem} width="50px" />}
          onExcelExportClickHandler={setAcceptedStatus}
          selectorOptions={selectorOptions}
          withExcelExport={["calw"].includes(appConfig.logo)}
          excelExportData={eigenkompostiererData
            .filter((el) => selectedItems.includes(el.id))
            .map((el) => {
            return {
              Status: el?.status ? statuses[el?.status] : "",
              "Datum (Meldung)": el?.createdAt
                ? format(new Date(el?.createdAt), "dd.MM.yyyy, HH:mm", {
                    locale: de,
                  })
                : "",
              Name: `${el?.userSurname}, ${el?.userName}`,
              Strasse: `${el?.street} ${el?.homeNumber} ${
                el?.hausNrZusatz ? " " + el?.hausNrZusatz : ""
              }`,
              Ort: `${el?.postCode ? el.postCode : ""} ${el?.placePart}`,
              Telefonnummer: `${el?.phoneNumber ? "+" + el.phoneNumber : ""}`,
              "E-Mail": `${el?.email ? el.email : ""}`,
              Kassenzeichen: `${el?.bookingCode}`,
              Nachricht: `${el?.message}`,
            };
          })}
          excelExportItem={[
            {
              field: "Status",
              title: "Status",
            },
            {
              field: "Datum (Meldung)",
              title: "Datum (Meldung)",
            },
            {
              field: "Name",
              title: "Name",
            },
            {
              field: "Strasse",
              title: "Strasse",
            },
            {
              field: "Ort",
              title: "Ort",
            },
            {
              field: "Telefonnummer",
              title: "Telefonnummer",
            },
            {
              field: "E-Mail",
              title: "E-Mail",
            },
            {
              field: "Kassenzeichen",
              title: "Kassenzeichen",
            },
            {
              field: "Nachricht",
              title: "Nachricht",
            },
          ]}
          firstItem={
            ["calw"].includes(appConfig.logo) ? (
              <GridColumn
                cell={FirstItem}
                width="40px"
                headerCell={FirstHeaderItem}
              />
            ) : null
          }
        />
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminEigenkompostierer;
