import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISperrmuel } from "../../api/spermuell/spermuell.api";
import API from "../../api/api";
import { setAdminLoader } from "./admin";
import {
  getAdminNotificationItemsCount,
  setErrorMessage,
  setIsConfirmationPopupActive,
  setIsLoading,
  setIsToasterError,
} from "./app";
import appConfig from "../../util/appConfig";

interface ISperrmuell {
  client: {
    dates: string[];
    streets: {
      strasseBezeichnung: string;
      strasseNummer: number;
      blockedHomeNumbers: { _id: string; from: string; to: string }[];
      ignore?: "active" | "partially" | "ignore";
      whiteListHomeNumbers?: { from: string; to: string }[];
      whiteListIgnore?: "active" | "partially" | "ignore";
      greyListHomeNumbers?: { from: string; to: string }[];
      greyListIgnore: "active" | "partially" | "ignore";
      gemeindeNummer?: number;
      ortsteilNummer?: number;
    }[];
    townAreas: {
      ortsteilNummer: number;
      orteilBezeichnung: string;
    }[];
    isStreetsLoaderActive: boolean;
    recaptchaToken: string;
    gemeinden: {
      id: string;
      gemeindeBezeichnung: string;
      gemeindePostleitzahl: number;
      gemeindeNummer: number;
    }[];
    stuecklisten: {
      abfallnr: number;
      abfallbez: string;
      sortnr: number;
      faktor: number;
    }[];
  };
  admin: {
    sperrmuelData: ISperrmuel[];
    sperrmuelDataTotalCount: number;
    sperrmuelItemInfo: ISperrmuel | null;
    isSperrmuelDataLoading: boolean;
  };
}

const initialState: ISperrmuell = {
  client: {
    townAreas: [],
    dates: [],
    streets: [],
    isStreetsLoaderActive: false,
    recaptchaToken: "",
    gemeinden: [],
    stuecklisten: [],
  },
  admin: {
    sperrmuelData: [],
    sperrmuelDataTotalCount: 0,
    sperrmuelItemInfo: null,
    isSperrmuelDataLoading: false,
  },
};

export const getAvailableDate = createAsyncThunk(
  "sperrmuell/client/getAvailableDates",
  async (obj: any, thunkApi) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const dates = API.spermuell.get.getAvailableDates(obj);
      thunkApi.dispatch(setIsLoading(false));

      return dates;
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const getAvailableEbwoDate = createAsyncThunk(
  "sperrmuell/client/getAvailableEbwoDate",
  async (
    obj: {
      pickupStreet: string;
      pickupHomeNumber: number;
      isOnGreyOrWhiteList?: boolean;
      strasseNummer?: string;
      leistungNr?: string[];
      gemeindeNr?: string;
      isExpress?: boolean;
    },
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const dates = API.spermuell.get.getEbwoDates(obj);
      thunkApi.dispatch(setIsLoading(false));

      return dates;
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);
export const getAvailableLraDate = createAsyncThunk(
  "sperrmuell/client/getAvailableLraDate",
  async (
    obj: {
      pickupStreet: string;
      pickupHomeNumber: number;
      isOnGreyOrWhiteList?: boolean;
      strasseNummer?: string;
      leistungNr?: string[];
      gemeindeNr?: string;
      isExpress?: boolean;
      postcode: string;
      gemeindeNummner?: string;
    },
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const dates = API.spermuell.get.getLraDates(obj);
      thunkApi.dispatch(setIsLoading(false));

      return dates;
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const getAvailableCalwDate = createAsyncThunk(
  "sperrmuell/client/getAvailableCalwDate",
  async (
    obj: {
      leistungNr?: string;
      streetNummer?: string;
      ortsteilNummer?: string;
      hausNr?: string;
    },
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const dates = API.spermuell.get.getCalwDates(obj);
      thunkApi.dispatch(setIsLoading(false));

      return dates;
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const getAvailableExpressDates = createAsyncThunk(
  "sperrmuell/client/getAvailableExpressDates",
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      const dates = await API.spermuell.get.getAvailableExpressDates();
      thunkApi.dispatch(setIsLoading(false));

      return dates;
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const postSperrmuell = createAsyncThunk(
  "sperrmuell/client/postSperrmuell",
  async (
    obj: { data: any; nextStepFunc: () => void; resetFormFunc: () => void },
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      await API.spermuell.post.clearBulkyGarbageRequest(obj.data);
      thunkApi.dispatch(setIsLoading(false));
      obj.resetFormFunc();
      obj.nextStepFunc();
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const postEbwoSperrmuell = createAsyncThunk(
  "sperrmuell/client/postEbwoSperrmuell",
  async (
    obj: {
      data: any;
      nextStepFunc: () => void;
      resetFormFunc: () => void;
      token: string;
    },
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      await API.spermuell.post.clearBulkyGarbageRequest({
        ...obj.data,
        token: obj.token,
      });
      thunkApi.dispatch(setIsLoading(false));
      obj.resetFormFunc();
      obj.nextStepFunc();
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const getSpermuellTownAreaStreets = createAsyncThunk(
  "sperrmuell/client/getSpermuellTownAreaStreets",
  async (_, thunkAPI) => {
    try {
      const streets = await API.spermuell.get.getTownAreaStreets();
      return streets;
    } catch (error) {
      thunkAPI.dispatch(setIsToasterError(false));
    }
  }
);

export const getSpermuellTownAreas = createAsyncThunk(
  "sperrmuell/client/getSpermuellTownAreas",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const townAreas = await API.spermuell.get.getTownAreas();
      thunkAPI.dispatch(setIsLoading(false));
      return townAreas;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(false));
    }
  }
);

export const getGemeinden = createAsyncThunk(
  "sperrmuell/client/getGemeinden",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const gemeinden = await API.spermuell.get.getGemeinden();

      return gemeinden;
    } catch (error) {
      thunkAPI.dispatch(setIsLoading(false));
      thunkAPI.dispatch(setIsToasterError(false));
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const getSperrmuelData = createAsyncThunk(
  "sperrmuell/admin/getSperrmuelData",
  async (
    {
      limit,
      page,
      direction,
      searchValue,
      sort,
      status,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string | undefined;
      page: number;
      limit: number;
      status?: string[];
    },
    thunkApi
  ) => {
    try {
      // thunkApi.dispatch(setDataLoading(true));
      thunkApi.dispatch(setAdminLoader(true));
      const sperrmuelData = await API.spermuell.get.getSperrmuelData({
        direction: direction || undefined,
        pageSize: limit,
        page,
        searchValue: searchValue || undefined,
        sort: sort || "",
        status,
      });
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      return sperrmuelData;
    } catch (error: any) {
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);
export const getSperrmuelDataSearch = createAsyncThunk(
  "sperrmuell/admin/getSperrmuelDataSearch",
  async (
    {
      limit,
      page,
      direction,
      searchValue,
      sort,
      status,
      signal,
      endDate,
      startDate,
      serviceType,
      trashType,
    }: {
      sort?: string;
      direction?: string;
      searchValue?: string | undefined;
      page: number;
      limit: number;
      status?: string[];
      serviceType?: string[];
      startDate?: Date | null;
      endDate?: Date | null;
      trashType?: string[];
      signal?: AbortSignal;
    },
    thunkApi
  ) => {
    try {
      // thunkApi.dispatch(setDataLoading(true));
      thunkApi.dispatch(setAdminLoader(true));
      const sperrmuelData = await API.spermuell.get.getSperrmuelData({
        direction: direction || undefined,
        pageSize: limit,
        page,
        searchValue: searchValue || undefined,
        sort: sort || undefined,
        status,
        endDate,
        startDate,
        signal,
        serviceType,
        trashType,
      });
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setAdminLoader(false));
      return sperrmuelData;
    } catch (error: any) {
      if (error !== "Request canceled") {
        // thunkApi.dispatch(setDataLoading(false));
        thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
        thunkApi.dispatch(setAdminLoader(false));
        thunkApi.dispatch(setIsToasterError(true));
      }
    }
  }
);

export const acceptBulkyTrash = createAsyncThunk(
  "sperrmuell/client/confirmTrash",
  async (token: string, thunkApi) => {
    try {
      thunkApi.dispatch(setIsLoading(true));
      await API.spermuell.get.acceptBulkyGarbageRequest(token);
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsConfirmationPopupActive(true));
    } catch (error) {
      thunkApi.dispatch(setIsLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const getSperrmuelDataById = createAsyncThunk(
  "sperrmuell/client/getSperrmuelDataById",
  async (id: string, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));
      // thunkApi.dispatch(setDataLoading(true));
      const data = await API.spermuell.get.getSperrmuelById(id);
      thunkApi.dispatch(setAdminLoader(false));
      // thunkApi.dispatch(setDataLoading(false));
      return data;
    } catch (error) {
      thunkApi.dispatch(setAdminLoader(false));
      // thunkApi.dispatch(setDataLoading(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const confirmSperrmuell = createAsyncThunk(
  "sperrmuell/admin/confirmSperrmuell",
  async (
    {
      accepted,
      id,
      status,
    }: { id: string; accepted?: boolean; status?: string },
    thunkApi
  ) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));

      const data = await API.spermuell.put.confirmSperrmuell({
        id,
        accepted,
        status,
      });
      if (["lra"].includes(appConfig.logo)) {
        thunkApi.dispatch(getAdminNotificationItemsCount());
      }
      thunkApi.dispatch(setAdminLoader(false));

      return data;
    } catch (error: any) {
      thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkApi.dispatch(setAdminLoader(false));
      thunkApi.dispatch(setIsToasterError(true));
    }
  }
);

export const deleteSperrmuellById = createAsyncThunk(
  "sperrmuell/admin/deleteSperrmuellById",
  async ({ id, navigate }: { id: string; navigate: () => void }, thunkApi) => {
    try {
      thunkApi.dispatch(setAdminLoader(true));

      await API.spermuell.delete.deleteSperrmuellById({ id });
      navigate();
    } catch (error: any) {
      thunkApi.dispatch(setErrorMessage(error?.response?.data?.message));
      thunkApi.dispatch(setIsToasterError(true));
    } finally {
      thunkApi.dispatch(setAdminLoader(false));
    }
  }
);

export const getStuecklisten = createAsyncThunk(
  "sperrmuell/client/getStuecklisten",
  async (
    {
      gemeindeNummer,
      leistungNr,
    }: {
      gemeindeNummer: number;
      leistungNr: number;
    },
    { dispatch }
  ) => {
    try {
      dispatch(setIsLoading(true));

      const stueckliste = await API.spermuell.get.getStuecklisten({
        gemeindeNummer,
        leistungNr,
      });

      return stueckliste;
    } catch (error: any) {
      dispatch(setErrorMessage(error?.response?.data?.message));
      dispatch(setIsToasterError(true));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);

export const changeSperrmuellItemsStatuses = createAsyncThunk(
  "sperrmuell/admin/changeSperrmuellItemsStatuses",
  async ({ ids, status }: { ids: string[]; status: string }, { dispatch }) => {
    try {
      dispatch(setAdminLoader(true));
      const data = await API.spermuell.put.changeSperrmuellItemsStatuses({
        ids,
        status,
      });

      return data;
    } catch (error: any) {
      dispatch(setIsToasterError(true));
      dispatch(setErrorMessage(error?.response?.data?.message));
    } finally {
      dispatch(setAdminLoader(false));
    }
  }
);

const sperrmuellSlice = createSlice({
  name: "sperrmuell",
  initialState,
  reducers: {
    clearSperrmuelData: (state) => {
      state.admin.sperrmuelData = [];
    },
    clearSperrmuelItemInfo: (state) => {
      state.admin.sperrmuelItemInfo = null;
    },
    setRecaptcheToken: (state, action: PayloadAction<string>) => {
      state.client.recaptchaToken = action.payload;
    },
    clearStucklisten: (state) => {
      state.client.stuecklisten = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableDate.fulfilled, (state, action) => {
      if (action.payload) state.client.dates = [...action.payload];
    });
    builder.addCase(getSpermuellTownAreaStreets.pending, (state) => {
      state.client.isStreetsLoaderActive = true;
    });
    builder.addCase(getSpermuellTownAreaStreets.fulfilled, (state, action) => {
      if (action.payload) state.client.streets = [...action.payload];
      state.client.isStreetsLoaderActive = false;
    });
    builder.addCase(getSpermuellTownAreaStreets.rejected, (state) => {
      state.client.isStreetsLoaderActive = false;
    });
    builder.addCase(getSpermuellTownAreas.fulfilled, (state, action) => {
      if (action.payload) state.client.townAreas = [...action.payload];
    });
    builder.addCase(getSperrmuelData.pending, (state) => {
      state.admin.isSperrmuelDataLoading = true;
    });
    builder.addCase(getSperrmuelData.fulfilled, (state, action) => {
      if (action.payload && action.payload.data.length) {
        state.admin.sperrmuelData = [...action.payload.data];
        state.admin.sperrmuelDataTotalCount = action.payload.totalCount;
      }
      state.admin.isSperrmuelDataLoading = false;
    });
    builder.addCase(getSperrmuelData.rejected, (state) => {
      state.admin.isSperrmuelDataLoading = false;
    });
    builder.addCase(getSperrmuelDataSearch.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.sperrmuelData = [...action.payload.data];
        state.admin.sperrmuelDataTotalCount = action.payload.totalCount;
      }
    });
    builder.addCase(getAvailableExpressDates.fulfilled, (state, action) => {
      // if (action.payload) state.client.dates = [...action.payload];
    });
    builder.addCase(getSperrmuelDataById.pending, (state) => {
      state.admin.isSperrmuelDataLoading = true;
    });
    builder.addCase(getSperrmuelDataById.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.sperrmuelItemInfo = action.payload;
      }
      state.admin.isSperrmuelDataLoading = false;
    });
    builder.addCase(getSperrmuelDataById.rejected, (state) => {
      state.admin.isSperrmuelDataLoading = false;
    });
    builder.addCase(confirmSperrmuell.pending, (state) => {
      state.admin.isSperrmuelDataLoading = true;
    });
    builder.addCase(confirmSperrmuell.fulfilled, (state, action) => {
      if (action.payload) {
        state.admin.sperrmuelItemInfo = action.payload;
      }
      state.admin.isSperrmuelDataLoading = false;
    });
    builder.addCase(confirmSperrmuell.rejected, (state) => {
      state.admin.isSperrmuelDataLoading = false;
    });
    builder.addCase(getGemeinden.pending, (state) => {});
    builder.addCase(getGemeinden.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.gemeinden = action.payload;
      }
    });
    builder.addCase(getGemeinden.rejected, (state) => {});
    builder.addCase(getStuecklisten.pending, (state) => {});
    builder.addCase(getStuecklisten.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.stuecklisten = action.payload;
      }
    });
    builder.addCase(getStuecklisten.rejected, (state) => {});
    builder.addCase(changeSperrmuellItemsStatuses.pending, (state) => {});
    builder.addCase(
      changeSperrmuellItemsStatuses.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.admin.sperrmuelData = action.payload;
        }
      }
    );
    builder.addCase(changeSperrmuellItemsStatuses.rejected, (state) => {});
  },
});

export const {
  clearSperrmuelData,
  clearSperrmuelItemInfo,
  setRecaptcheToken,
  clearStucklisten,
} = sperrmuellSlice.actions;

export const sperrmuellReducer = sperrmuellSlice.reducer;
